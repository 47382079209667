import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Curs Online per a Policia Municipal de Mataró (2024)</Title>
                <Text>
                    Tras l'èxit de l'any anterior on el 83% dels aptes a Mataró van ser alumnes de
                    iOpos Acadèmia.
                    <br />
                    <br />
                    Arriba un curs específic per preparar l'accés a la policia local de Mataró 2024
                    <br />
                    <br />
                    Enguany amb una important NOVETAT!
                    <br />
                    <br />
                    Contingut:
                    <br />
                    <br />
                    - Actualitat setmanal en forma de notícies, test per posar-te a prova i un vídeo
                    explicatiu mensual.
                    <br />
                    <br />
                    - Assignatura de cultura general bàsica i test
                    <br />
                    <br />
                    - Assignatura de cultura general AVANÇADA
                    <br />
                    <br />
                    - Assignatura de cultura general per a CRACKS amb videos
                    <br />
                    <br />
                    - El municipi i les seves curiositats.
                    <br />
                    <br />
                    - El consistori
                    <br />
                    <br />
                    - Premis 2024
                    <br />
                    <br />
                    - Esports 2024
                    <br />
                    <br />
                    - Personalitats 2024
                    <br />
                    <br />
                    - 6 Exàmens oficials
                    <br />
                    <br />
                    - La prova pràctica amb solucions detallades i consells
                    <br />
                    <br />
                    - Els 40 temes exigits a les bases, amb test e idees força.
                    <br />
                    <br />
                    - Simulacres mensuals
                    <br />
                    <br />
                    - Un estudi acurat de les preguntes més importants
                    <br />
                    <br />
                    I la NOVETAT... La prova pràctica OFICIAL de l'any passat corregida per l'alumne
                    que va quedar número 1 a l'oposició.
                    <br />
                    <br />
                    Ja veus que és sens dubte el millor curs.
                    <br />
                    <br />
                    Estem segurs que amb aquest curs et posicionaràs entre els millors.
                    <br />
                    <br />
                    Un curs molt viu i en constant evolució.
                    <br />
                    <br />
                    A més us donarem accés a un grup de WhatsApp exclusiu amb Kahoot! setmanals.
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen.
                    <br />
                    <br />
                    Entra i avança al teu ritme.
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/NWOToRcwpgc"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
